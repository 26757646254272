
import * as bootstrap from 'bootstrap';
import {Options, Vue} from 'vue-class-component';
import {isProxy, toRaw} from 'vue';
import FbTokenService from '../services/fbtoken.service';
import EnvironmentService from '../services/environment.service';
import {IEnvironment, IFbToken, IFunctionType} from '@/models';

@Options({
  components: {}

})
export default class Home extends Vue {
  items: IFbToken[] = [];
  // functionTypes: IFunctionType[] = [{id: 1, functionType: 'functionType1'}, {id: 2, functionType: 'functionType2'}];
  functionTypes: IFunctionType[] = [];
  // selectedFunctionTypeId: number | undefined;
  environments: IEnvironment[] = [];
  levels = ['admin', 'employee'];

  editedItem: IFbToken = ({} as any) as IFbToken;
  newItem: IFbToken = ({} as any) as IFbToken;
  itemToDelete: IFbToken = ({} as any) as IFbToken;

  // sorting
  orderItems = {
    column: '',
    order: '',
  };

  // filter
  isFiltered = false;
  filterItems = {
    environment: '',
    level: '',
    functionTypeId: '',
  };

  private tokenService = new FbTokenService();
  private environmentService = new EnvironmentService();

  mounted(): void {
    this.fetchEnvironments();
    this.fetchTokens();
    this.fetchFunctionTypes();
  }

  showEditModal(item: IFbToken): void {
    // this.selectedFunctionTypeId = item.functionTypeId;
    this.editedItem = {...item};
    this.editModal.show();
  }

  showDeleteConfirm(item: IFbToken): void {
    this.itemToDelete = item;
    this.deleteConfirm.show();
  }

  getFunctionTypeName(id: number): string | undefined {
    for (const functionType in this.functionTypes) {
      if (this.functionTypes[functionType].id === id) {
        return this.functionTypes[functionType].functionType;
      }
    }
  }

  showCreateModal(item: IFbToken): void {
    this.newItem = {...item};
    this.createModal.show();
  }

  get editModal(): bootstrap.Modal {
    return new bootstrap.Modal(document.getElementById('edit-modal') as HTMLElement);
  }

  get createModal(): bootstrap.Modal {
    return new bootstrap.Modal(document.getElementById('create-modal') as HTMLElement);
  }

  get deleteConfirm(): bootstrap.Modal {
    return new bootstrap.Modal(document.getElementById('confirm-delete') as HTMLElement);
  }

  async fetchEnvironments(): Promise<void> {
    this.environments = await this.environmentService.getEnvironments();
  }

  async fetchTokens(): Promise<void> {
    this.items = await this.tokenService.getTokens({...this.orderItems, ...this.filterItems});
  }

  async fetchFunctionTypes(): Promise<void> {
    this.functionTypes = await this.tokenService.getFunctionTypes();
  }

  public async deleteItem(item: IFbToken): Promise<void> {
    if (isProxy(item)) {
      const itemToDelete = toRaw<IFbToken>(item);
      if (itemToDelete.id) { // should have an id at this point
        await this.tokenService.delete(itemToDelete.id);
      }
      this.deleteConfirm.hide();
      await this.fetchTokens();
    }
  }

  public async createItem(item: IFbToken): Promise<void> {
    // if (this.selectedFunctionTypeId != null) {
    //   item.functionTypeId = this.selectedFunctionTypeId;
    // }
    if (isProxy(item)) {
      const itemToCreate = toRaw<IFbToken>(item);
      await this.tokenService.create(itemToCreate);
      this.createModal.hide();
      await this.fetchTokens();
    }
  }

  public async updateItem(item: IFbToken): Promise<void> {
    if (isProxy(item)) {
      const itemToUpdate = toRaw<IFbToken>(item);
      await this.tokenService.update(itemToUpdate);
      this.editModal.hide();
      await this.fetchTokens();
    }
  }

  public async onSort(column: string): Promise<void> {
    if (this.orderItems.column === column) {
      if (this.orderItems.order === '') {
        this.orderItems.order = 'asc';
      }
      else if (this.orderItems.order === 'asc') {
        this.orderItems.order = 'desc';
      }
      else {
        this.orderItems.order = '';
        this.orderItems.column = '';
      }
    }
    else {
      this.orderItems.column = column;
      this.orderItems.order = 'asc';
    }
    await this.fetchTokens();
  }

  public isColumnSorted(column: string, order: string): boolean {
    return this.orderItems.column === column && this.orderItems.order === order;
  }

  public async onFilter(): Promise<void> {
    this.isFiltered = true;
    await this.fetchTokens();
  }

  public get isFilterButtonDisabled(): boolean {
    return this.filterItems.environment === '' && this.filterItems.level === '' && this.filterItems.functionTypeId === '';
  }

  public async onClearFilter(): Promise<void> {
    this.isFiltered = false;
    this.filterItems = {
      environment: '',
      level: '',
      functionTypeId: '',
    };
    await this.fetchTokens();
  }
}
