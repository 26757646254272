import { BaseService } from "./base.service";
// import jwt from 'jsonwebtoken';

interface AuthApiResponse {
    user: string
    email: string
    token: string
}

export default class AuthenticationService extends BaseService {
    baseUrl: string;

    constructor() {
      super();
      this.baseUrl = process.env.VUE_APP_AUTH_API_URL!;
    }

    /**
     * Authenticate a user using https://im-api.revenuedriver.com/api
     * @param email
     * @param password
     * @returns Promise<any>
     */
    async authenticate(email: string, password: string): Promise<any> {
      try {
        const requestOptions = {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password:  password

            })
        };

        const response = await fetch(`${this.baseUrl}`, requestOptions);
        if (response.ok) {
          // const token = jwt.sign({ data: email }, process.env.VUE_APP_TOKEN_SECRET!,
          //   {
          //     expiresIn: "1d",
          //   }
          // );
          const { user, token } = <AuthApiResponse>await response.json();
          return { email, user, token };
        } else {
          return Promise.reject(new Error("Invalid username or password"));
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }
}
