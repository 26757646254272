
import { Vue } from 'vue-class-component';
export default class App extends Vue {
    logout() {
      sessionStorage.clear();
      this.$router.push("/login");
    }

    get isAuthenticated() {
      return !!sessionStorage.getItem("access-token");
    }
}
