import {IFbToken} from '@/models/fbtoken';
import {BaseService} from './base.service';
import {IFunctionType} from "@/models/functionType";


export default class FbTokenService extends BaseService {
    baseUrl: string;

    constructor() {
        super();
        this.baseUrl = process.env.VUE_APP_API_BASE_URL + '/fbtokens';
    }

    /**
     * Return single record
     * @param id number
     * @returns Promise<IFbToken>
     */
    async getToken(id: number): Promise<IFbToken> {
        const response = await fetch(`${this.baseUrl}/${id}`);
        const data = await response.json();
        return data.fbtokens;
    }


    /**
     * Create a token
     * @param item IFbToken
     * @returns Promise<IFbToken>
     */
    async create(item: IFbToken): Promise<IFbToken> {
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`
            },
            body: JSON.stringify(item)
        };
        const response = await fetch(`${this.baseUrl}`, requestOptions);
        return await response.json();
    }

    /**
     * Delete an FB Token record
     * @param item number
     * @returns Promise<any>
     */
    async delete(id: number): Promise<any> {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`
            }
        }
        await fetch(`${this.baseUrl}/${id}`, requestOptions);
    }

    /**
     * PUT /api/fktokens/:id
     * @param item IFbToken
     * @returns Promise<IFbToken>
     */
    async update(item: IFbToken): Promise<IFbToken> {
        const requestOptions = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`
            },
            body: JSON.stringify({
                token: item.token,
                environment: item.environment,
                level: item.level,
                functionTypeId:item.functionTypeId
            })
        };
        const response = await fetch(`${this.baseUrl}/${item?.id}`, requestOptions);
        return await response.json();
    }

    /**
     * Return all tokens (no paging at moment)
     * @returns Promise<IFbToken[]>
     */
    async getTokens(args: Record<string, string>): Promise<IFbToken[]> {
        const params = this.getTruthyValues(args);
        const url = `${this.baseUrl}` + '?' + new URLSearchParams(params);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`
            }
        };
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return data.data;
    }


    /**
     * Return all function types
     * @returns Promise<IFunctionType>
     */
    async getFunctionTypes(): Promise<IFunctionType[]> {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`
            }
        };
        const response = await fetch(`${this.baseUrl}/functionTypes`, requestOptions);

        const data = await response.json();
        return data.data;
    }


    private getTruthyValues(args: Record<string, string>) {
        const entries = Object.entries(args);
        const filteredEntries = entries.filter(el => el[1]);
        return Object.fromEntries(filteredEntries);
    }
}
