import {IEnvironment} from '@/models';
import {BaseService} from './base.service';


export default class EnvironmentService extends BaseService {
    baseUrl: string;

    constructor() {
        super();
        this.baseUrl = process.env.VUE_APP_API_BASE_URL + '/environments';
    }

    /**
     * Return all environments
     * @returns Promise<IEnvironment[]>
     */
    async getEnvironments(): Promise<IEnvironment[]> {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`
            }
        };
        const response = await fetch(`${this.baseUrl}`, requestOptions);
        return response.json();
    }

}
