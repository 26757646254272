import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Login from "@/views/Login.vue"
import Home from "@/views/Home.vue"


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Home | Revenuedriver S.R.L",
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: "Login | Revenuedriver S.R.L",
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth) {
    if (!sessionStorage.getItem("access-token")) next("/login");
    else next();
  } else next();
});

export default router
