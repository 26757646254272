
import { Options, Vue } from 'vue-class-component';
import { AuthenticationService } from '@/services';

@Options({
})
export default class Login extends Vue {
    email = "";
    errMessage = "";
    password = "";
    showError = false;

  /**
    Login to authentication service
    @returns Promise<void>
  */
  async login(): Promise<void> {
    try {
      this.showError = false;
      const response = await new AuthenticationService().authenticate(this.email, this.password);
      if (response) {
        const { token, user: name } = response;
        sessionStorage.setItem("access-token", token);
        sessionStorage.setItem("email", this.email);
        sessionStorage.setItem("name", name);
        this.$router.push("/");
      }
    } catch(err) {
      this.showError = true;
      this.errMessage = err.message;
    }

  }
}
